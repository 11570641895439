<template>
    <div id="secure">
        <h1>Bilder {{ footSide }} bearbeiten</h1>
        <div v-if="step === STEPS.ROTATE.id" class="md-layout md-alignment-top-center">
            <md-card class="md-layout-item md-size-70 md-small-size-90">
                <md-card-header>
                    <div class="md-title">{{ STEPS.ROTATE.title }}</div>
                </md-card-header>
                <md-card-content class="md-layout md-gutter">
                    <div class="md-layout-item md-size-100 md-layout md-alignment-center-center">
                        <div class="md-layout-item md-size-25 md-small-size-100">
                            <h2>Fuß</h2>
                            <md-button class="md-raised md-accent" v-on:click="turnImage(rotate.foot)">
                                <md-icon>rotate_right</md-icon>
                            </md-button>
                        </div>
                        <div class="md-layout-item md-size-25 md-small-size-100">
                            <img :src="rotate.foot.data" :style="'transform: rotate(' + rotate.foot.rotation + 'deg);'"
                                 class="finalImage">
                        </div>
                    </div>
                    <div class="md-layout-item md-size-100 md-layout md-alignment-center-center">
                        <br>
                    </div>
                    <div class="md-layout-item md-size-100 md-layout md-alignment-center-center">
                        <div class="md-layout-item md-size-25 md-small-size-100">
                            <h2>Abdruck</h2>
                            <md-button class="md-raised md-accent" v-on:click="turnImage(rotate.imprint)">
                                <md-icon>rotate_right</md-icon>
                            </md-button>
                        </div>
                        <div class="md-layout-item md-size-25 md-small-size-100">
                            <img :src="rotate.imprint.data" :style="'transform: rotate(' + rotate.imprint.rotation + 'deg);'"
                                 class="finalImage">
                        </div>
                    </div>
                </md-card-content>
                <md-card-actions>
                    <md-button :disabled="false" class="md-raised md-primary" v-on:click="stepForward()">
                        Weiter
                    </md-button>
                </md-card-actions>
            </md-card>
        </div>

        <div v-if="step === STEPS.CROP.id" class="md-layout md-alignment-top-center">
            <md-card class="md-layout-item md-size-70 md-small-size-90">
                <md-card-header>
                    <div class="md-title">{{ STEPS.CROP.title }}</div>
                </md-card-header>
                <md-card-content class="md-layout md-gutter">
                    <div class="md-layout-item md-size-100 md-layout md-alignment-center-center">
                        <div class="md-layout-item md-size-25 md-small-size-100">
                            <h2>Fuß</h2>
                            <md-button class="md-raised md-accent" v-on:click="openCropDialog(crop.foot)">
                                <md-icon>crop</md-icon>
                            </md-button>
                        </div>
                        <div class="md-layout-item md-size-25 md-small-size-100">
                            <img :src="croppedFoot" class="finalImage">
                        </div>
                    </div>
                    <div class="md-layout-item md-size-100 md-layout md-alignment-center-center">
                        <br>
                    </div>
                    <div class="md-layout-item md-size-100 md-layout md-alignment-center-center">
                        <div class="md-layout-item md-size-25 md-small-size-100">
                            <h2>Abdruck</h2>
                            <md-button class="md-raised md-accent" v-on:click="openCropDialog(crop.imprint)">
                                <md-icon>crop</md-icon>
                            </md-button>
                        </div>
                        <div class="md-layout-item md-size-25 md-small-size-100">
                            <img :src="croppedImprint" class="finalImage">
                        </div>
                    </div>
                </md-card-content>
                <md-card-actions>
                    <md-button class="md-raised md-primary" v-on:click="stepBackwards()">
                        Zurück
                    </md-button>
                    <md-button :disabled="!croppingDone" class="md-raised md-primary" v-on:click="stepForward()">
                        Weiter
                    </md-button>
                </md-card-actions>
            </md-card>
        </div>


        <div v-if="step === STEPS.FOOTDETECTION.id" class="md-layout md-alignment-top-center">
            <md-card class="md-layout-item md-size-70 md-small-size-90">
                <md-card-header>
                    <div class="md-title">{{ STEPS.FOOTDETECTION.title }}</div>
                </md-card-header>
                <md-card-content class="md-layout md-gutter md-centered md-alignment-bottom-center">
                    <div class="md-layout-item md-size-33 md-small-size-100">
                        <h2>Überlagerte Bilder</h2>
                        <img :src="footdetection.overlay.data" class="finalImage" alt="Übereinandergelegte Bilder von Fuß und Abdruck">
                    </div>
                    <div class="md-layout-item md-size-33 md-small-size-100">
                        <h2>Originales Fußbild</h2>
                        <img :src="footdetection.original.foot.data" class="finalImage" alt="Originalbild vom Fuß">
                    </div>
                    <div class="md-layout-item md-size-33 md-small-size-100">
                        <h2>Nach Anwendung der YCrCb-Parameter</h2>
                        <img :src="footdetection.detected.data" class="finalImage" alt="Bild nach Anwendung des YCrCb-Algorithmus">
                    </div>
                    <div class="md-layout-item md-size-75 md-small-size-100">
                        <br>
                        <h2>YCrCb-Parameter</h2>
                        <table style="border: none; width: 100%">
                            <tr>
                                <td>
                                    <label id="label_minY" ref="label_minY" for="slider_minY">minY</label>
                                </td>
                                <td>
                                    <input id="slider_minY" ref="slider_minY" type="range" min="0" max="255" v-model="footdetection.ycrcb.minY"
                                           style="width: 100%" v-on:input="runFootDetection">
                                </td>
                                <td>
                                    <span id="value_minY" ref="value_minY">{{ footdetection.ycrcb.minY }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label id="label_maxY" ref="label_maxY" for="slider_maxY">maxY</label>
                                </td>
                                <td>
                                    <input id="slider_maxY" ref="slider_maxY" type="range" min="0" max="255" v-model="footdetection.ycrcb.maxY"
                                           style="width: 100%" v-on:input="runFootDetection">
                                </td>
                                <td>
                                    <span id="value_maxY" ref="value_maxY">{{ footdetection.ycrcb.maxY }}</span>
                                </td>
                            </tr>
                        </table>
                        <br>

                        <br>
                    </div>
                </md-card-content>
                <md-card-actions>
                    <md-button class="md-raised md-primary" v-on:click="stepBackwards()">
                        Zurück
                    </md-button>
                    <md-button :disabled="false" class="md-raised md-primary" v-on:click="stepForward()">
                        Weiter
                    </md-button>
                </md-card-actions>
            </md-card>
        </div>


        <div v-if="step === STEPS.AMPLIFYING.id" class="md-layout md-alignment-top-center">
            <md-card class="md-layout-item md-size-70 md-small-size-90">
                <md-card-header>
                    <div class="md-title">{{ STEPS.AMPLIFYING.title }}</div>
                </md-card-header>
                <md-card-content class="md-layout md-gutter md-centered md-alignment-bottom-center">
                    <div class="md-layout-item md-size-33 md-small-size-100">
                        <h2>Ausgeschnittener Fuß (YCrCb)</h2>
                        <img :src="amplifying.cutout.data" class="finalImage">
                    </div>
                    <div class="md-layout-item md-size-33 md-small-size-100">
                        <h2>Kontrastverstärkter Abdruck</h2>
                        <img :src="amplifying.contrast.data" class="finalImage">
                    </div>
                    <div class="md-layout-item md-size-33 md-small-size-100">
                        <h2>THRESHOLD</h2>
                        <img :src="amplifying.threshold.data" class="finalImage">
                    </div>
                    <div class="md-layout-item md-size-100">
                        <h2>REGLER Threshold</h2>
                        <input id="slider_thres1" ref="slider_thres1" type="range" min="0" max="255" v-model="amplifying.threshold.thresh1min"
                               style="width: 50%" v-on:input="drawThreshold()">
                        <span id="value_thres1" ref="value_thres1">{{ amplifying.threshold.thresh1min }}</span>
                        <br>
                    </div>
                    <div class="md-layout-item md-size-50">
                        <h2>Welche Bilder sollen für den Download erzeugt werden?</h2>
                        <div>
                            <table width="50%">
                                <tr class="hide_all">
                                    <td>
                                        <md-switch v-model="amplifying.cutout.selected">Ausgeschnittenener Abdruck</md-switch>
                                    </td>
                                    <td>
                                        <md-switch v-model="amplifying.contrast.selected">Contrast</md-switch>
                                    </td>
                                    <td>
                                        <md-switch v-model="amplifying.threshold.selected">Threshold</md-switch>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </md-card-content>
                <md-card-actions>
                    <md-button class="md-raised md-primary" v-on:click="stepBackwards()">
                        Zurück
                    </md-button>
                    <md-button :disabled="false" class="md-raised md-primary" v-on:click="stepForward()">
                        Weiter
                    </md-button>
                </md-card-actions>
            </md-card>
        </div>


        <div v-if="step === STEPS.PREVIEW.id" class="md-layout md-alignment-top-center">
            <md-card class="md-layout-item md-size-70 md-small-size-90">
                <md-card-header>
                    <div class="md-title">{{ STEPS.PREVIEW.title }}</div>
                </md-card-header>
                <md-card-content class="md-layout md-gutter md-centered md-alignment-bottom-center">
                    <div class="md-layout-item md-size-100">
                        Folgende Bilder werden für Dich in hoher Qualität berechnet.
                        <br>
                        Im Anschluss kannst Du das alles als PDF herunterladen.
                    </div>
                    <div class="md-layout-item md-size-100">
                        <h2>Gedrehtes & Entzerrtes Fußbild</h2>
                        <img :src="crop.foot.cropped_data" class="finalImage">
                    </div>
                    <div class="md-layout-item md-size-100">
                        <h2>Gedrehtes & Entzerrtes Abdruckbild</h2>
                        <img :src="crop.imprint.cropped_data" class="finalImage">
                    </div>
                    <div class="md-layout-item md-size-100">
                        <h2>Mit Fuß überlagerter Abdruck</h2>
                        <img :src="footdetection.overlay.data" class="finalImage">
                    </div>
                    <div class="md-layout-item md-size-100" v-if="amplifying.cutout.selected">
                        <h2>Freigestellter Fuß</h2>
                        <img :src="amplifying.cutout.data" class="finalImage">
                    </div>
                    <div class="md-layout-item md-size-100" v-if="amplifying.contrast.selected">
                        <h2>Kontrastverstärkter Abdruck</h2>
                        <img :src="amplifying.contrast.data" class="finalImage">
                    </div>
                    <div class="md-layout-item md-size-100" v-if="amplifying.threshold.selected">
                        <h2>THRESHOLD</h2>
                        <img :src="amplifying.threshold.data" class="finalImage">
                    </div>
                </md-card-content>
                <md-card-actions>
                    <md-button class="md-raised md-primary" v-on:click="stepBackwards()">
                        Zurück
                    </md-button>
                    <md-button :disabled="false" class="md-raised md-primary" v-on:click="send()">
                        Senden
                    </md-button>
                </md-card-actions>
            </md-card>
        </div>

        <md-button class="md-raised md-accent" v-on:click="abortEdit()">Abbrechen</md-button>

        <crop-dialog :crop.sync="crop.cropDialog"></crop-dialog>
    </div>
</template>

<script>
import cv_comm from "@/helpers/openCV_communicator";
import Partner_PAW_Picset_edit_cropdialog from "./Partner_PAW_Picset_edit_cropdialog";

const emptyImg = "data:image/jpeg;base64,";
const canvasOffset = 15 // in pixels

export default {
    name: 'Partner_PAW_Picset_edit',
    components: {
        CropDialog: Partner_PAW_Picset_edit_cropdialog,
    },
    props: {},
    data: () => ({
        loading: {
            opencv: false,
            foot: false,
            imprint: false,
            pps: false,
        },

        step: -1,

        STEPS: {
            INITIAL: {
                id: -1,
                title: "Initialisierung",
            },
            ROTATE: {
                id: 0,
                title: "Rotation",
            },
            CROP: {
                id: 1,
                title: "Zuschnitt",
            },
            FOOTDETECTION: {
                id: 2,
                title: "Fußerkennung",
            },
            AMPLIFYING: {
                id: 3,
                title: "Verstärkung",
            },
            PREVIEW: {
                id: 4,
                title: "Vorschau",
            }
        },

        initial: {},

        rotate: {
            init: false,
            foot: {
                data: null,
                imgobj: null,
                rotation: 0,
                width: 0,
                height: 0,
            },
            imprint: {
                data: null,
                imgobj: null,
                rotation: 0,
                width: 0,
                height: 0,
            }
        },

        crop: {
            init: false,
            cropDialog: {
                active: false,
                autoclose: false,
                target: null,
            },
            foot: {
                data: null,
                imgobj: null,
                cropped_data: null,
                cropped_imgobj: null,
                width: 0,
                height: 0,
                done: false,
                canvas: null,
                dots: [],
                upper_mags: {},
                lower_mags: {},
            },
            imprint: {
                data: null,
                imgobj: null,
                cropped_data: null,
                cropped_imgobj: null,
                width: 0,
                height: 0,
                done: false,
                canvas: null,
                dots: [],
                upper_mags: {},
                lower_mags: {},
            }
        },

        footdetection: {
            overlay: {
                data: null,
                imgobj: null,
            },
            original: {
                foot: {
                    data: null,
                    imgobj: null,
                    canvas: null,
                },
                imprint: {
                    data: null,
                    imgobj: null,
                    canvas: null,
                },
            },
            detected: {
                data: null,
                imgobj: null,
                canvas: null,
            },
            ycrcb: {
                minY: 0,
                maxY: 255,
                minCr: 0,
                maxCr: 255,
                minCb: 0,
                maxCb: 255,
            }
        },

        amplifying: {
            cutout: {
                data: null,
                imgobj: null,
                canvas: null,
                selected: true,
            },
            contrast: {
                data: null,
                imgobj: null,
                canvas: null,
                selected: true,
            },
            threshold: {
                data: null,
                imgobj: null,
                canvas: null,
                selected: true,
                thresh1min: 145,
                thresh1max: 255
            },
        },

        preview: {

        },

        finals: {

        }

    }),
    mounted() {
        this.$store.commit("setRXTX", true)

        // load PAW details
        this.$store.dispatch("loadPawDetails", this.$route.params.pawId).then(() => {
            const pps = this.paw_picset

            this.rotate.foot.data = emptyImg + pps.feet
            getImageDimensions(this.rotate.foot).then(() => {
                this.loading.foot = true
            })

            this.rotate.imprint.data = emptyImg + this.paw_picset.imprint
            getImageDimensions(this.rotate.imprint).then(() => {
                this.loading.imprint = true
            })

            // load PPS-Config (if there is any)
            this.$store.dispatch("loadPpsConfig", {
                pawId: this.$route.params.pawId,
                ppsId: pps.id
            }).then(() => {
                this.loading.pps = true;
            }).catch(() => { // error or no pps was delivered by the API
                this.loading.pps = true;
            })
        })

        // load openCV
        cv_comm.load().then(() => {
            this.loading.opencv = true;
        })

        // start over if everything loaded
        new Promise((resolve) => {
            const i = setInterval(() => {
                for (let e in this.loading) {
                    if (!this.loading[e])
                        return
                }
                clearInterval(i)
                resolve("Loaded!")
            }, 100)
        }).then(() => {
            const ppsConfig = this.$store.state.ppsConfig
            if(ppsConfig) {
                // Rotations
                if(ppsConfig.feetRotation)
                    this.rotate.foot.rotation = (ppsConfig.feetRotation * 90) % 360
                if(ppsConfig.imprintRotation)
                    this.rotate.imprint.rotation = (ppsConfig.imprintRotation * 90) % 360

                console.log(this.rotate)
                console.log(ppsConfig)

                // Cropping
                for (let i = 0; i < 4; i += 1) {
                    this.crop.foot.dots.push({
                        x: ppsConfig.feetPoints["x" + (i + 1)] * this.rotate.foot.width,
                        y: ppsConfig.feetPoints["y" + (i + 1)] * this.rotate.foot.height
                    })
                    this.crop.imprint.dots.push({
                        x: ppsConfig.imprintPoints["x" + (i + 1)] * this.rotate.imprint.width,
                        y: ppsConfig.imprintPoints["y" + (i + 1)] * this.rotate.imprint.height
                    })
                }

                // YCrCb
                this.footdetection.ycrcb = JSON.parse(ppsConfig.algorithm).YCrCb
                for(let name in this.footdetection.ycrcb){
                    this.footdetection.ycrcb[name] = parseInt(this.footdetection.ycrcb[name])
                }
                console.log(this.footdetection.ycrcb)

                // Threshold
                this.amplifying.threshold.thresh1min = ppsConfig.threshold

                // Download-Selections
                this.amplifying.cutout.selected = ppsConfig.cutSelected
                this.amplifying.contrast.selected = ppsConfig.contrastSelected
                this.amplifying.threshold.selected = ppsConfig.thresholdSelected
            } else {
                console.log("No PPSConfig found.")
            }
            console.log(this.crop)
            this.step = this.STEPS.ROTATE.id
            this.$store.commit("setRXTX", false)
        })
    },
    computed: {
        croppedFoot: function (){
            if(this.crop.foot.done)
                return this.crop.foot.cropped_data
            else
                return this.crop.foot.data
        },
        croppedImprint: function (){
            if(this.crop.imprint.done)
                return this.crop.imprint.cropped_data
            else
                return this.crop.imprint.data
        },
        croppingDone: function () {
            return this.crop.foot.done && this.crop.imprint.done
        },
        showLoadStdValues: function () {
            return this.sliders.length > 0;
        },
        footSide: function () {
            return this.$route.params.picIdx === "0" ? "links" : "rechts";
        },
        paw_picset: function () {
            try {
                for (let s of this.$store.state.paw_detail.pawPicSets) {
                    if (s.feetType === parseInt(this.$route.params.picIdx)) {
                        return s
                    }
                }
            } catch (e) {
                // do nothing
            }
            return undefined;
        },
    },
    methods: {
        /**
         * Opens the dialog for cropping the image.
         * @param target objects that holds data for the iimage to be cropped
         */
        openCropDialog(target) {
            this.crop.cropDialog.target = target
            this.crop.cropDialog.active = true
        },
        /**
         * go one step forward
         */
        async stepForward() {
            switch (this.step) {
                case this.STEPS.ROTATE.id:
                    this.persistRotation();
                    this.gotoCrop()
                    this.step = this.STEPS.CROP.id
                    break;
                case this.STEPS.CROP.id:
                    await this.gotoFootdetection()
                    this.step = this.STEPS.FOOTDETECTION.id
                    break;
                case this.STEPS.FOOTDETECTION.id:
                    this.gotoAmplifying()
                    this.step = this.STEPS.AMPLIFYING.id
                    break;
                case this.STEPS.AMPLIFYING.id:
                    this.step = this.STEPS.PREVIEW.id
                    break;
                default:
                    break;
            }
        },
        /**
         * go one step back
         */
        stepBackwards() {
            switch (this.step) {
                case this.STEPS.CROP.id:
                    this.step = this.STEPS.ROTATE.id
                    break;
                case this.STEPS.FOOTDETECTION.id:
                    this.step = this.STEPS.CROP.id
                    break;
                case this.STEPS.AMPLIFYING.id:
                    this.step = this.STEPS.FOOTDETECTION.id
                    break;
                case this.STEPS.PREVIEW.id:
                    this.step = this.STEPS.AMPLIFYING.id
                    break;
                default:
                    break;
            }
        },
        /**
         * Initializes the cropping step
         */
        async gotoCrop() {
            for (let i of ["foot", "imprint"]) {
                delete this.crop[i].canvas
                const canvas = document.createElement("canvas")
                this.crop[i].canvas = canvas

                await getImageDimensions(this.crop[i]).then(() => {
                    canvas.width = this.crop[i].width + 2 * canvasOffset
                    canvas.height = this.crop[i].height + 2 * canvasOffset
                })
                //this.crop.cropDialog.autoclose = true
                //this.openCropDialog(this.crop[i])
                //while (this.crop.cropDialog.active){
                //    console.log("waiting")
                //}
            }
        },
        /**
         * Turn an image 90deg clockwise
         * @param image the timage to be rotated
         */
        turnImage(image) {
            image.rotation = (image.rotation + 90) % 360
        },
        /**
         * Calculate rotated image
         */
        persistRotation() {
            for (let i of ["foot", "imprint"]) {
                let e = this.rotate[i]
                if (e.rotation !== 0) {
                    const degrees = e.rotation
                    let canvas = document.createElement("canvas")
                    let ctx = canvas.getContext("2d")
                    if (degrees === 180) {
                        canvas.width = e.width
                        canvas.height = e.height
                    } else {
                        canvas.width = e.height // !!! done by purpose, needed for rotation
                        canvas.height = e.width // !!! done by purpose, needed for rotation
                    }
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    if (degrees === 90 || degrees === 270) {
                        ctx.translate(e.height / 2, e.width / 2);
                    } else {
                        ctx.translate(e.width / 2, e.height / 2);
                    }
                    ctx.rotate(degrees * Math.PI / 180);
                    ctx.drawImage(e.imgobj, -e.width / 2, -e.height / 2);
                    this.crop[i].data = canvas.toDataURL()
                } else {
                    this.crop[i].data = this.rotate[i].data + ""
                }
            }
        },
        async gotoFootdetection() {
            // init this steps variables
            this.footdetection.original.foot.data = this.crop.foot.cropped_data
            this.footdetection.original.foot.imgobj = this.crop.foot.cropped_imgobj
            this.footdetection.original.imprint.data = this.crop.imprint.cropped_data
            this.footdetection.original.imprint.imgobj = this.crop.imprint.cropped_imgobj

            // draw foot to canvas
            const imgobj_foot = this.footdetection.original.foot.imgobj
            const canv_foot = document.createElement("canvas")
            canv_foot.width = 450
            canv_foot.height = 450 * 1.414
            const ctx_foot = canv_foot.getContext('2d')
            ctx_foot.drawImage(imgobj_foot, 0, 0, 450, 450 * 1.414)
            this.footdetection.original.foot.canvas = canv_foot

            // draw imprint to canvas
            const imgobj_imprint = this.footdetection.original.imprint.imgobj
            const canv_imprint = document.createElement("canvas")
            canv_imprint.width = 450
            canv_imprint.height = 450 * 1.414
            const ctx_imprint = canv_imprint.getContext('2d')
            ctx_imprint.drawImage(imgobj_imprint, 0, 0, 450, 450 * 1.414)
            this.footdetection.original.imprint.canvas = canv_imprint

            // init-run YCrCb and Overlay-Routines
            await this.YCrCb()
            await this.drawOverlayed()
        },
        /**
         * Init the Amplifying-Step
         */
        async gotoAmplifying() {
            await this.drawCutout()
            await this.drawContrast()
            await this.drawThreshold()
        },
        /**
         * Runs whenever user changes slider parameters
         */
        async runFootDetection(){
            await this.YCrCb();
            await this.drawOverlayed()
        },
        /**
         * Run the YCrCb-Algorithm with given arguments
         * @returns {Promise<void>}
         */
        async YCrCb() {
            const canv_orig = this.footdetection.original.foot.canvas
            const ctx_orig = canv_orig.getContext('2d')
            const image = ctx_orig.getImageData(0, 0, canv_orig.width, canv_orig.height)

            // Processing image
            let args = {}
            for (let e in this.footdetection.ycrcb) {
                args[e] = parseInt(this.footdetection.ycrcb[e])
            }
            const payload = {
                command: "YCrCb",
                image: image,
                args: args
            }
            const processedImage = await cv_comm.imageProcessing(payload)

            // Paint on invisible canvas to get DataURL
            const ctx_detected = document.createElement("canvas").getContext("2d")
            ctx_detected.canvas.width = canv_orig.width
            ctx_detected.canvas.height = canv_orig.height
            await ctx_detected.putImageData(processedImage.data.payload, 0, 0)
            this.footdetection.detected.data = ctx_detected.canvas.toDataURL()
            this.footdetection.detected.canvas = ctx_detected.canvas
        },
        /**
         * Run the overlaying-routine
         * @returns {Promise<void>}
         */
        async drawOverlayed(){
            const canv_detectedFoot = this.footdetection.detected.canvas
            const canv_imprint = this.footdetection.original.imprint.canvas
            const ctx_detectedFoot = canv_detectedFoot.getContext("2d")
            const ctx_imprint = canv_imprint.getContext("2d")

            const imageDetectedFoot = ctx_detectedFoot.getImageData(0,0, canv_detectedFoot.width, canv_detectedFoot.height)
            const imageImprint = ctx_imprint.getImageData(0, 0, canv_imprint.width, canv_imprint.height)

            const payload = {
                command: "overlay",
                imgFoot: imageDetectedFoot,
                imgImprint: imageImprint,
                args: {}
            }
            const processedImage = await cv_comm.imageProcessing(payload)

            // Paint on invisible canvas to get DataURL
            const ctx_overlayed = document.createElement("canvas").getContext("2d")
            ctx_overlayed.canvas.width = canv_detectedFoot.width
            ctx_overlayed.canvas.height = canv_detectedFoot.height
            await ctx_overlayed.putImageData(processedImage.data.payload, 0, 0)
            this.footdetection.overlay.data = ctx_overlayed.canvas.toDataURL()
        },
        async drawCutout(){
            const canv_orig = this.footdetection.original.foot.canvas
            const ctx_orig = canv_orig.getContext('2d')
            const image = ctx_orig.getImageData(0, 0, canv_orig.width, canv_orig.height)

            const processedImage = await cv_comm.imageProcessing({
                command: "applyMask",
                image: image,
                args: {}
            })
            const ctxMasked = document.createElement("canvas").getContext("2d")
            ctxMasked.canvas.width = canv_orig.width
            ctxMasked.canvas.height = canv_orig.height
            await ctxMasked.putImageData(processedImage.data.payload, 0, 0)

            this.amplifying.cutout.data = ctxMasked.canvas.toDataURL()
            this.amplifying.cutout.canvas = ctxMasked.canvas
        },
        async drawContrast(){
            const canv_orig = this.footdetection.original.imprint.canvas
            const ctx_orig = canv_orig.getContext('2d')
            const image = ctx_orig.getImageData(0, 0, canv_orig.width, canv_orig.height)

            const processedImage = await cv_comm.imageProcessing({
                command: "contrast",
                image: image,
                args: {}
            })
            const ctxMasked = document.createElement("canvas").getContext("2d")
            ctxMasked.canvas.width = canv_orig.width
            ctxMasked.canvas.height = canv_orig.height
            await ctxMasked.putImageData(processedImage.data.payload, 0, 0)

            this.amplifying.contrast.data = ctxMasked.canvas.toDataURL()
            this.amplifying.contrast.canvas = ctxMasked.canvas
        },
        async drawThreshold(){
            const canv_orig = this.footdetection.original.imprint.canvas
            const ctx_orig = canv_orig.getContext('2d')
            const image = ctx_orig.getImageData(0, 0, canv_orig.width, canv_orig.height)

            const processedImage = await cv_comm.imageProcessing({
                command: "threshold",
                image: image,
                args: {
                    thresh1: parseInt(this.amplifying.threshold.thresh1min),
                    thresh1Max: parseInt(this.amplifying.threshold.thresh1max)
                }
            })
            const ctx = document.createElement("canvas").getContext("2d")
            ctx.canvas.width = canv_orig.width
            ctx.canvas.height = canv_orig.height
            await ctx.putImageData(processedImage.data.payload, 0, 0)

            this.amplifying.threshold.data = ctx.canvas.toDataURL()
            this.amplifying.threshold.canvas = ctx.canvas
        },
        abortEdit() {
            console.log(this.$store.state.paw_detail)
            this.$router.back();
        },
        /**
         * send data to Server
         */
        send() {
            const t = this;
            const alg = {
                YCrCb: this.footdetection.ycrcb
            }
            const feetPoints = {}
            const imprintPoints = {}
            for (let i = 0; i < 4; i += 1) {
                feetPoints["x" + (i + 1)] = this.crop.foot.dots[i].x / this.crop.foot.width
                feetPoints["y" + (i + 1)] = this.crop.foot.dots[i].y / this.crop.foot.height
                imprintPoints["x" + (i + 1)] = this.crop.imprint.dots[i].x / this.crop.imprint.width
                imprintPoints["y" + (i + 1)] = this.crop.imprint.dots[i].y / this.crop.imprint.height
            }
            let req = {
                pawPicSetId: this.paw_picset.id,
                pawId: this.$route.params.pawId,
                algorithm: JSON.stringify(alg),
                threshold: this.amplifying.threshold.thresh1min,
                cutSelected: this.amplifying.cutout.selected,
                contrastSelected: this.amplifying.contrast.selected,
                thresholdSelected: this.amplifying.threshold.selected,
                feetPoints: feetPoints,
                imprintPoints: imprintPoints,
                feetRotation: this.rotate.foot.rotation / 90,
                imprintRotation: this.rotate.imprint.rotation / 90
            }

            t.$store.dispatch("sendPawConfig", req).then(async function () {
                await t.$store.dispatch("reloadPawDetails", t.$route.params.pawId)
                t.$router.push("/partner/paw/detail/"+t.$route.params.pawId);
                t.$store.dispatch("showSnackbar", {text: "Bildparameter erfolgeich gespeichert."})
            })
        }
    }
}

/**
 * Calculate width and height of an image and save it to the parameter object
 * @param image Object holding informations about the image. Destination of calculated info.
 * @returns {Promise<unknown>}
 */
function getImageDimensions(image = new HTMLImageElement()) {
    return new Promise(function (resolved) {
        var i = new Image()
        i.onload = function () {
            image.width = i.width
            image.height = i.height
            image.imgobj = i
            resolved()
        };
        i.src = image.data
    })
}
</script>

<style scoped>
#secure {
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    padding: 20px;
    margin-top: 10px;
}

canvas {
    max-width: 200px;
}

canvas.dotDial {
    max-width: 400px;
    width: 300px;
}

canvas.finalImage {
    max-height: 200px;
    width: auto;
}

img.finalImage {
    max-height: 200px;
}
</style>


<!-- TODO Werte validieren -->
