class CVH {
    /**
     * We will use this method privately to communicate with the worker and
     * return a promise with the result of the event. This way we can call
     * the worker asynchronously.
     */
    _dispatch(event) {
        const {msg} = event

        // Signaling that opencv is currently busy
        if(this._status && this._status[msg] && this._status[msg][0] === 'loading')
            return new Promise((res, rej) => {
                rej("still working")
            })

        this._status[msg] = ['loading']
        this.worker.postMessage(event)
        return new Promise((res, rej) => {
            let interval = setInterval(() => {
                const status = this._status[msg]
                if (status) {
                    if (status[0] === 'done')
                        res(status[1])
                    if (status[0] === 'error')
                        rej(status[1])
                    if (status[0] !== 'loading') {
                        delete this._status[msg]
                        clearInterval(interval)
                    }
                } else {
                    clearInterval(interval)
                }
            }, 50)
        })
    }

    /**
     * First, we will load the worker and capture the onmessage
     * and onerror events to always know the status of the event
     * we have triggered.
     *
     * Then, we are going to call the 'load' event, as we've just
     * implemented it so that the worker can capture it.
     */

    load() {
        this._status = {}
        const path = '/js/opencv/opencv.worker.js'
        this.worker = new Worker(path) // load worker
        // Capture events and save [status, event] inside the _status object
        this.worker.onmessage = (e) => {
            if(e.data.msg === "error"){
                console.log("openCV: error --> exit!")
                //console.log(this._status)
                //console.log(e)
                /*
                console.log("restarting opencv")
                this._status = {}
                const path = '/js/opencv/opencv.worker.js'
                this.worker = new Worker(path) // load worker
                this._dispatch({msg: 'load', payload: window.location.pathname})
                */
            } else {
                this._status[e.data.msg] = ['done', e]
            }
        }
        this.worker.onerror = (e) => {this._status[e.data.msg] = ['error', e]}

        return this._dispatch({msg: 'load', payload: window.location.pathname})
    }

    /**
     * We are going to use the _dispatch event we created before to
     * call the postMessage with the msg and the image as payload.
     *
     * Thanks to what we've implemented in the _dispatch, this will
     * return a promise with the processed image.
     */
    imageProcessing(payload) {
        return this._dispatch({msg: 'imageProcessing', payload})
    }
}

export default new CVH();
