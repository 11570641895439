<template>
    <md-dialog :md-active.sync="crop.active" :md-click-outside-to-close="false" :md-close-on-esc="false" @md-opened="onCropDialogOpened" @md-closed="onClose" class="md-size-50">
        <md-dialog-title>
            Detailansicht & Punkte schieben
        </md-dialog-title>
        <md-dialog-content>
            <div ref="canvascontainer" >
            </div>
        </md-dialog-content>
        <md-dialog-actions>
            <md-button class="md-raised md-accent" v-on:click="manualReset">Zurücksetzen!</md-button>
            <md-button class="md-raised md-accent" v-on:click="crop.active = false">Abbrechen!</md-button>
            <md-button class="md-raised" v-on:click="closeCropDialog()">Speichern!</md-button>
        </md-dialog-actions>
    </md-dialog>
</template>

<script>
import cv_comm from "@/helpers/openCV_communicator";
const canvasOffset = 15 // in pixels
const dotDimension = 15 // in pixels

export default {
    name: "Partner_PAW_Picset_edit_cropdialog",
    props: {
        crop: {
            active: Boolean,
            target: {
                data: null,
                imgobj: null,
                cropped_data: null,
                cropped_imgobj: null,
                width: 0,
                height: 0,
                done: false,
                canvas: null,
                dots: [],
                upper_mags: {},
                lower_mags: {},
            },
            autoclose: Boolean,
        },
    },
    mounted() {

    },
    data: () => ({
        dots: []
    }),
    computed: {
        upper_mags: function () { return this.crop.target.upper_mags },
        lower_mags: function () { return this.crop.target.lower_mags }
    },
    methods: {
        /**
         * Resets the dots an repaints the canvas
         */
        manualReset(){
            this.resetdots()
            this.dotDialDraw()
        },
        /**
         * Resets all dots to their position on the images edges
         */
        resetdots(){
            this.dots = []
            this.dots.push({x: canvasOffset, y: canvasOffset})
            this.dots.push({x: canvasOffset, y: this.crop.target.height + canvasOffset})
            this.dots.push({x: this.crop.target.width + canvasOffset, y: this.crop.target.height + canvasOffset})
            this.dots.push({x: this.crop.target.width + canvasOffset, y: canvasOffset})
        },
        /**
         * Launched when cropDialog was successfully opened
         */
        onCropDialogOpened() {
            // get current canvas and apply style
            const canvas = this.crop.target.canvas
            canvas.style = "border: 1px solid black;"

            // get container for canvas
            const container = this.$refs["canvascontainer"]

            // remove existing canvases and append the one needed for current target
            while(container.hasChildNodes()){
                container.removeChild(container.firstChild)
            }
            container.appendChild(this.crop.target.canvas)

            // if needed reset the dot positions
            if(!this.crop.target.dots || this.crop.target.dots.length === 0){
                this.resetdots()
            } else {
                this.dots = []
                for(let dot of this.crop.target.dots){
                    this.dots.push({
                        x: dot.x + canvasOffset,
                        y: dot.y + canvasOffset
                    })
                }
            }

            // calculate mag size and generate magnifier objects
            this.crop.target.upper_mags.radius = this.crop.target.width * 0.1
            this.crop.target.upper_mags.leftmag = {
                x: this.crop.target.width * 0.1 + canvasOffset,
                y: this.crop.target.width * 0.1 + canvasOffset,
                active: false
            }
            this.crop.target.lower_mags.leftmag = {
                x: this.crop.target.width * 0.1 + canvasOffset,
                y: this.crop.target.height - this.crop.target.width * 0.1 + canvasOffset,
                active: false
            }
            this.crop.target.upper_mags.rightmag = {
                x: this.crop.target.width * 0.9 + canvasOffset,
                y: this.crop.target.width * 0.1 + canvasOffset,
                active: false
            }
            this.crop.target.lower_mags.rightmag = {
                x: this.crop.target.width * 0.9 + canvasOffset,
                y: this.crop.target.height - this.crop.target.width * 0.1 + canvasOffset,
                active: false
            }
            console.log(this.crop.target)

            // calculate dot size
            let rect = this.crop.target.canvas.getBoundingClientRect();
            this.crop.dotSize = (this.crop.target.width + 2 * canvasOffset) / rect.width * dotDimension

            // MOUSE - Events
            canvas.addEventListener("mousedown", e => {
                this.dotDialTouchStart(e)
            })
            canvas.addEventListener("mousemove", e => {
                this.dotDialTouchMove(e)
            })
            canvas.addEventListener("mouseup", e => {
                this.dotDialTouchEnd(e)
            })

            // TOUCH - Events
            // touch events are handled by the normal mouse-click-handlers
            // but they need to be recalculated to fit
            // TODO check if needed!!!
            canvas.addEventListener("touchstart", e => {
                let touch = e.touches[0];
                let mouseEvent = new MouseEvent("mousedown", {
                    clientX: touch.clientX,
                    clientY: touch.clientY
                });
                this.dotDialTouchStart(mouseEvent)
            })
            canvas.addEventListener("touchmove", e => {
                let touch = e.touches[0];
                let mouseEvent = new MouseEvent("mousemove", {
                    clientX: touch.clientX,
                    clientY: touch.clientY
                });
                this.dotDialTouchMove(mouseEvent)
            })
            canvas.addEventListener("touchend", e => {
                this.dotDialTouchEnd(e)
            })

            // inititally draw the canvas
            this.dotDialDraw()
//
//            if(this.crop.autoclose){
//                this.crop.autoclose = false
//                this.closeCropDialog()
//            }
        },
        /**
         * Return mouse position cleaned for resized image and image position on screen.
         */
        getMousePos(evt) {
            const canvas = this.crop.target.canvas
            const rect = canvas.getBoundingClientRect();
            return {
                x: (evt.clientX - rect.left) / (rect.right - rect.left) * canvas.width,
                y: (evt.clientY - rect.top) / (rect.bottom - rect.top) * canvas.height
            };
        },
        /**
         * Called when touch / mouseclick starts within canvas.
         */
        dotDialTouchStart(e) {
            let pos = this.getMousePos(e)

            // mousedown stuff here
            this.dotDialMouseX = pos.x;
            this.dotDialMouseY = pos.y;
            this.dotDialMouseDown = true;
        },
        /**
         * Called when touch / mouseclick ends within canvas.
         */
        dotDialTouchEnd() {
            this.dotDialMouseDown = false;

            this.upper_mags.leftmag.active = false;
            this.upper_mags.rightmag.active = false;

            this.lower_mags.leftmag.active = false;
            this.lower_mags.rightmag.active = false;
            this.dotDialDraw();
            for (let i = 0; i < 4; i++) {
                const d = this.dots[i]             // current dot
                const dd = this.dots[(i + 1) % 4]    // next dot
                const ddd = this.dots[(i + 2) % 4]   // opposite dot
                if ((d.x === dd.x && d.y === dd.y) || (d.x === ddd.x && d.y === ddd.y)) {
                    if (d.x > this.dotDimension / 2)
                        d.x = d.x - this.dotDimension / 2
                    if (d.y > this.dotDimension / 2)
                        d.y = d.y - this.dotDimension / 2
                }
            }
        },
        /**
         * Called when mouse/touchpoint is moved within the canvas.
         */
        dotDialTouchMove(e) {
            if (!this.dotDialMouseDown) {
                return;
            }
            let pos = this.getMousePos(e)

            // mousedown stuff here
            let mouseX = pos.x;
            let mouseY = pos.y;
            const canvas = this.crop.target.canvas
            let ctx = canvas.getContext("2d")

            // mousemove stuff here
            for (let i = 0; i < this.dots.length; i += 1) {
                let dot = this.dots[i]
                this.drawDot(dot, null);
                if (ctx.isPointInPath(this.dotDialMouseX, this.dotDialMouseY)) {
                    dot.x = mouseX; //+= (mouseX - this.dotDialMouseX);
                    dot.y = mouseY; //+= (mouseY - this.dotDialMouseY);
                    if (i === 0) {
                        this.upper_mags.rightmag.active = true
                    } else if (i === 1) {
                        this.lower_mags.rightmag.active = true
                    } else if (i === 2) {
                        this.lower_mags.leftmag.active = true
                    } else if (i === 3) {
                        this.upper_mags.leftmag.active = true
                    }
                }
            }
            this.dotDialMouseX = mouseX;
            this.dotDialMouseY = mouseY;

            this.dotDialDraw();
        },
        /**
         * Redraw the whole canvas
         */
        dotDialDraw() {
            const canvas = this.crop.target.canvas
            let ctx = canvas.getContext("2d")
            let dots = this.dots

            // clear and reprint all in white, then draw image
            ctx.clearRect(0, 0, canvas.width, canvas.height)
            ctx.fillStyle = "#FFFFFF"
            ctx.fillRect(0, 0, canvas.width, canvas.height)
            ctx.drawImage(this.crop.target.imgobj, canvasOffset, canvasOffset)

            // set color for vertices
            ctx.strokeStyle = "#FF0000"
            ctx.lineWidth = 3;

            // Draw the lines to connect the four edges
            for (let i = 0; i < dots.length; i += 1) {
                let nxtDot = dots[(i + 1) % dots.length]
                ctx.beginPath()
                ctx.moveTo(dots[i].x, dots[i].y);
                ctx.lineTo(nxtDot.x, nxtDot.y);
                ctx.closePath()
                ctx.stroke();
                this.drawDot(dots[i], null)
            }

            // Draw magnifiers
            this.drawMag(this.upper_mags.leftmag)
            this.drawMag(this.upper_mags.rightmag)
            this.drawMag(this.lower_mags.leftmag)
            this.drawMag(this.lower_mags.rightmag)
        },
        drawMag(mag) {
            // only draw mag if needed only happens if user moves a dot.
            if (!mag.active) {
                return
            }
            console.log(mag)

            // get correct canvas and context
            const canvas = this.crop.target.canvas
            let ctx = canvas.getContext("2d")

            // get mouse position
            let x = this.dotDialMouseX
            let y = this.dotDialMouseY

            // draw image inside the cross hai circle
            ctx.save();
            ctx.beginPath()
            ctx.arc(mag.x, mag.y, this.upper_mags.radius, 0, 2 * Math.PI)
            ctx.closePath()
            ctx.clip()
            ctx.fillStyle = "#FFFFFF"
            ctx.fillRect(mag.x - this.upper_mags.radius, mag.y - this.upper_mags.radius, this.upper_mags.radius * 2, this.upper_mags.radius * 2)
            ctx.drawImage(this.crop.target.imgobj,
                x - 10 - canvasOffset, y - 10 - canvasOffset, 20, 20,
                mag.x - this.upper_mags.radius, mag.y - this.upper_mags.radius,
                this.upper_mags.radius * 2, this.upper_mags.radius * 2)
            ctx.restore();

            // draw circle
            ctx.beginPath()
            ctx.arc(mag.x, mag.y, this.upper_mags.radius, 0, 2 * Math.PI)
            ctx.stroke();
            ctx.closePath()

            // draw hair cross
            ctx.beginPath()
            ctx.moveTo(mag.x, mag.y - this.upper_mags.radius);
            ctx.lineTo(mag.x, mag.y + this.upper_mags.radius);
            ctx.moveTo(mag.x - this.upper_mags.radius, mag.y);
            ctx.lineTo(mag.x + this.upper_mags.radius, mag.y);
            ctx.stroke();
            ctx.closePath()

        },
        /**
         * Draw a Dot
         */
        drawDot(dot, color) {
            // get correct canvas and context
            const canvas = this.crop.target.canvas
            let ctx = canvas.getContext("2d")

            // set color
            if (color) {
                ctx.fillStyle = color
            } else {
                ctx.fillStyle = "#FF0000"
            }

            // draw
            ctx.beginPath()
            ctx.arc(dot.x, dot.y, dotDimension / 2, 0, 2 * Math.PI)
            ctx.fill()
            ctx.stroke()
            ctx.closePath()
        },
        /**
         * closeCropDialog does the following things
         * - trigger the crop process
         * - save data
         * - close dialog
         * @returns {Promise<void>} Promise can be safely ignored
         */
        async closeCropDialog() {
            // create temporary canvas (without offset & dots)
            let canvasTmp = document.createElement("canvas")
            canvasTmp.width = this.crop.target.width
            canvasTmp.height = this.crop.target.height
            let ctxTmp = canvasTmp.getContext("2d")

            // clear new canvas and draw image, get image data
            ctxTmp.clearRect(0, 0, canvasTmp.width, canvasTmp.height)
            ctxTmp.drawImage(this.crop.target.imgobj, 0, 0)
            const image = ctxTmp.getImageData(0, 0, canvasTmp.width, canvasTmp.height)

            // Calculate crop points removing the offset
            const dotsTmp = []
            for (let dot of this.dots) {
                dotsTmp.push({
                    x: Math.abs(dot.x - canvasOffset),
                    y: Math.abs(dot.y - canvasOffset)
                })
            }

            // Trigger openCV to crop the image
            let processedImage = await cv_comm.imageProcessing({
                command: "warp",
                image: image,
                args: {dots: dotsTmp, height: image.height}
            })

            // Print openCVs result to a canvas
            canvasTmp.height = processedImage.data.payload.height
            canvasTmp.width = processedImage.data.payload.width

            // Generate image data from canvas and generate image
            ctxTmp.putImageData(processedImage.data.payload, 0, 0)
            let img = new Image();
            const t = this
            img.onload = function () {
                // set image done
                t.crop.target.done = true
                // close dialog
                t.crop.active = false
            }
            img.src = canvasTmp.toDataURL()

            // Save data to be readable from calling component
            this.crop.target.cropped_data = img.src;
            this.crop.target.cropped_imgobj = img;
            this.crop.target.dots = dotsTmp;
        },
        onClose(){
            // do nothing
        }
    }
}
</script>

<style scoped>

</style>
